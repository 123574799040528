@use "@angular/material" as mat;

// Layout
$gutter: 16px !default;
$content: 30px !default;

// Sidenav
$sidenav-width: 280px !default;
$sidenav-collapsed-width: 60px !default;
$sidenav-width-mobile: 280px !default;

// Toolbar
$toolbar-height-desktop: 64px !default;
$toolbar-height-mobile: 56px !default;

// Topmenu
$topmenu-sticky-position-desktop: $toolbar-height-desktop !default;
$topmenu-sticky-position-mobile: $toolbar-height-mobile !default;

// Typography
$font-family-sans-serif: "Roboto", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif !default;
$font-family-monospace: "Roboto Mono", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-default: 14px !default;

// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  xsmall: 0,
  small: 600px,
  medium: 960px,
  large: 1280px,
  xlarge: 1920px,
) !default;

// Material colors
$mat-colors: (
  red: mat.$m2-red-palette,
  pink: mat.$m2-pink-palette,
  purple: mat.$m2-purple-palette,
  deep-purple: mat.$m2-deep-purple-palette,
  indigo: mat.$m2-indigo-palette,
  blue: mat.$m2-blue-palette,
  light-blue: mat.$m2-light-blue-palette,
  cyan: mat.$m2-cyan-palette,
  teal: mat.$m2-teal-palette,
  green: mat.$m2-green-palette,
  light-green: mat.$m2-light-green-palette,
  lime: mat.$m2-lime-palette,
  yellow: mat.$m2-yellow-palette,
  amber: mat.$m2-amber-palette,
  orange: mat.$m2-orange-palette,
  deep-orange: mat.$m2-deep-orange-palette,
  brown: mat.$m2-brown-palette,
  gray: mat.$m2-gray-palette,
  blue-gray: mat.$m2-blue-gray-palette,
  white: white,
  black: black,
  light: white,
  dark: rgba(black, 0.87),
) !default;

// The material default animation curves
$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
