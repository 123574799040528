.modalHeader {
  display: flex;
  height: 50px;
  width: 100%;
  padding: 5px 15px;
  border-bottom: 1px solid;
  border-color: rgba(82, 63, 105, 0.06);
  .modalTitle {
    font-size: 16px;
    margin: 5px 8px;
    line-height: 35px;
    font-weight: 500;
  }
  .avatarDetails {
    display: flex;
    align-items: flex-start;
    margin: 5px 10px;
    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
    }
  }
  img {
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
}
.modal-close-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #161d38;
  right: 10px;
  position: absolute !important;
  [dir="rtl"] & {
    left: 10px;
    right: unset;
  }
  .mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
  }
}

.dark {
  .modalHeader {
    border-color: rgba(212, 212, 212, 0.06);
    .modalTitle {
      color: #ffffff;
    }
  }
  .modal-close-button .mat-icon {
    color: #ffffff;
  }
}
