@use 'assets/styles/grid';
@use 'assets/styles/helpers';
@use 'assets/styles/material';
@use 'assets/styles/layout/reboot';
@use 'assets/styles/layout/misc';
@use 'assets/styles/layout/colors';
/* You can add global styles to this file, and also import other style files */
@import './styles/cometa-theme.scss';
@import './styles/elements/button.element';
@import './styles/variables';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

.form-field-full {
    width: 100%; /* Ensure it takes the full width of its parent */
    max-width: 100%;
    .mat-mdc-form-field {
        width: 100%;
    }
}

.full-width {
    width: 100%;
}

.app-data-grid {
    overflow-x: auto;
    background-color: #fff !important;
}

.cursor-pointer {
    cursor: pointer;
}

/**
snackbar style
**/
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: unset !important;
}

.snackbar-warning {
    background-color: var(--warningColor);
    color: var(--textColor);

    .mat-button-wrapper {
        color: var(--btnbackgroundColor) !important; // action text color
    }
}

.snackbar-error {
    background-color: var(--errorColor);
    color: var(--textColor);
    white-space: pre-wrap;

    .mat-button-wrapper {
        color: var(--btnbackgroundColor) !important; // action text color
    }
}

.snackbar-sucess {
    background-color: var(--sucessColor);
    color: var(--textColor);
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--textColor) !important;
}

/*list page style*/
.grid-container {
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1.2rem;
    margin: 1.2rem;
}

/** Form*/
.form-container {
    background: var(--03-on-primary-high-emphasis, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1.2rem;
    margin: 1.2rem;
}

.title-text {
    display: flex;
    padding: 0.2rem;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.5px;
}

.sub-title-text {
    display: flex;
    padding: 0.2rem;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.5px;
}

.list-header-container {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 1.2rem 1.2rem 0;
}

.search-add-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.mat-drawer::-webkit-scrollbar {
    display: none;
}
.mat-drawer {
    background-color: #fff !important;
    width: unset !important;
}

.mat-toolbar {
    background-color: #fff !important;
}

.mat-card {
    background-color: #fff !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mat-drawer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.mat-mdc-raised-button {
    font-size: 16px;
    font-weight: 500;
}

.button {
    width: 100%;
    background-color: var(--btnbackgroundColor) !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: var(--scrollbarBgColor);
    /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumbBgColor);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid orange;
    /* creates padding around scroll thumb */
}

/**style for the expansion screen**/
.data-container {
    display: flex;
    // padding: 16px;
    align-items: flex-start;
    margin: 16px;
    flex: 1 0 0;
    flex-direction: column;
}

.expansion-panel-title {
    color: var(--m-2-on-surface-medium-emphasis, rgba(0, 0, 0, 0.6));
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.expansion-panel-subtitle {
    align-self: stretch;
    color: var(--m-3-sys-light-on-surface, #1f1b16);
    /* M2/Body 2 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.25px;
}

.expansion-panel-subtitle-smaller {
    align-self: stretch;
    color: var(--m-3-sys-light-on-surface, #1f1b16);
    /* M2/Body 2 */
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.25px;
}

.mat-mdc-card {
    background-color: #fff !important;
}

.expansion-panel-content-container {
    display: flex;
    padding-bottom: 0px;
    align-items: flex-start;
    gap: var(--no-radius, 0px);
    align-self: stretch;
    background-color: #1e1b16;
}

.mat-mdc-paginator-container {
    background-color: #fff;
}

.expansion-pannel-header {
    height: auto !important;
    padding: 0 0.2rem !important;
}

.mat-expansion-panel {
    background-color: #fff !important;
}

.mat-expansion-panel-body {
    padding: unset !important;
}

.mat-column-select {
    overflow: initial;
}

.mat-mdc-header-row {
    background-color: #f0f0f0;
}

th {
    text-transform: capitalize;
}

.mat-mdc-row:hover .mat-mdc-cell {
    background-color: #f0f0f0;
}

.mat-mdc-row .mat-mdc-cell {
    background-color: #fff;
}

.grid-wrapper {
    display: flex;
    // padding: unset !important ;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    align-self: stretch;
}

.grid-row {
    display: flex;
    align-items: flex-start;
    gap: 33px;
    width: 100%;
    flex-wrap: wrap;
}

// Datepicker style

.mat-datepicker-content .mat-calendar {
    background-color: #fbf2e9;
}

.mat-calendar-table-header-divider::after {
    display: none;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #3d3833;
}

.mat-calendar-body-selected {
    background: #3d3833;
    color: #fff;
    box-shadow: none !important;
}

.mat-calendar-table-header th {
    font-weight: 400;
    color: #1f1b16;
}

//progressbar style
::ng-deep .mat-progress-bar {
    height: 30px !important;
}

// :host ::ng-deep mat-progress-bar {
//   height: 10px !important;
// }

:host ::ng-deep mat-progress-bar .mdc-linear-progress__bar-inner {
    border-top-width: 10px;
}

.save-btn {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--m-2-primary-50, rgba(255, 187, 13, 0.5));
    background: var(--g-board-ffffff, #fff);
}

.custom-form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.custom-form .custom-field {
    flex: 0 0 calc(50% - 40px);
    max-width: calc(50% - 40px);
    margin: 0 20px 20px;
    display: flex;
}

.custom-field .mdc-floating-label {
    color: #000;
}

.custom-field-confirm-password {
    display: flex;
    flex: 0 0 calc(50% - 40px);
    max-width: calc(50% - 40px);
    margin: 0 20px 20px;
}

.csv-template-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    border-radius: 8px;
    color: #000;
    border: 1px solid var(--m-3-sys-light-outline, #807667);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    color: #000;
}

.mdc-list-item__primary-text {
    white-space: nowrap !important;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
}
