@use "scrollbar";
@use "icon";

.scrollbar-thin {
  @include scrollbar.thin();
}

.scrollbar-none {
  @include scrollbar.none();
}

@each $size in [18, 24, 36, 48] {
  .icon-#{$size} {
    @include icon.icon($size);
  }
}

@media (width <= 720px) {
  .hide-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }
}
