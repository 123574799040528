:root {
  --heading1: 60px;
  --heading2: 48px;
  --heading3: 32px;
  --heading4: 24px;
  --heading5: 20px;
  --heading6: 18px;
  --subtitle1: 18px;
  --body1: 16px;
  --body2: 14px;
  --button: 16px;
  --caption: 12px;
  --caption2: 10px;
  --overline: 11px;
  --btnbackgroundColor: #F9BC49;
  --primaryColor: #FFBB0D;
  --primary900Color: #7B5701;
  --primary800Color: #896101;
  --primary700Color: #9A6C01;
  --primary600Color: #B17D00;
  --primary500Color: #D09305;
  --primary400Color: #E7A600;
  --primary300Color: #F1AD00;
  --primary200Color: #FFBB0D;
  --primary100Color: #FCCB4E;
  --primary050Color: #FFE093;
  --secondaryColor: #B3B6BB;
  --secondary900Color: #000000;
  --secondary800Color: #212327;
  --secondary700Color: #343843;
  --secondary600Color: #555A65;
  --secondary500Color: #5D626F;
  --secondary400Color: #80838B;
  --secondary300Color: #92959E;
  --secondary200Color: #B3B6BB;
  --secondary100Color: #D2D3D5;
  --secondary050Color: #F0F0F0;
  --errorColor: #d32f2f;
  --sucessColor: #2e7d32;
  --warningColor: #ff9800
}

// colo for loader
$color-white: #ffffff;
$slider-bg: #ececed;

$color-error: #cb0e0f;

$sm-shadow: 0px 1px 3px rgba(98, 98, 98, 0.1);


// mixin that enables css variables in light mode

@mixin lighten() {
  --bgColor: var(--secondary050Color);
  --loginBgColor: var(--secondary050Color);
  --textColor: var(--secondary900Color);
  --borderColor: var(--secondary900Color);
  --splashScreenBgColor: var(--primary200Color);
  --scrollbarBgColor: var(--color-white);
  --scrollbarThumbBgColor: var(--primary200Color);
}
