/** Override Material styles */

@use "../layout/variables";

.mat-mdc-card {
  margin-bottom: variables.$gutter;
}

.mat-mdc-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.form-field-full {
  .mat-mdc-form-field {
    width: 100%;
  }
}
