
@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

// Define a light theme
$_palettes: (
  primary: (
    0: #000000,
    10: #271900,
    20: #412d00,
    25: #4f3700,
    30: #5d4200,
    35: #6c4d00,
    40: #7b5800,
    50: #9a7000,
    60: #bb8800,
    70: #dda100,
    80: #ffbb0c,
    90: #ffdea5,
    95: #ffefd7,
    98: #fff8f3,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #251a04,
    20: #3c2e15,
    25: #47391f,
    30: #53452a,
    35: #605034,
    40: #6c5c3f,
    50: #867556,
    60: #a18e6e,
    70: #bda987,
    80: #d9c4a0,
    90: #f6e0bb,
    95: #ffefd7,
    98: #fff8f3,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #0a2007,
    20: #1f361a,
    25: #2a4124,
    30: #354d2f,
    35: #405939,
    40: #4c6545,
    50: #647e5c,
    60: #7d9874,
    70: #97b38d,
    80: #b2cfa7,
    90: #ceebc2,
    95: #dcf9cf,
    98: #ecffe2,
    99: #f7ffee,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1e1b16,
    20: #34302a,
    25: #3f3b35,
    30: #4b4640,
    35: #57524b,
    40: #635e57,
    50: #7c766f,
    60: #969088,
    70: #b1aaa2,
    80: #cdc5bd,
    90: #e9e1d9,
    95: #f8efe7,
    98: #fff8f3,
    99: #fffbff,
    100: #ffffff,
    4: #110e09,
    6: #16130e,
    12: #231f1a,
    17: #2d2924,
    22: #38342e,
    24: #3d3933,
    87: #e1d9d0,
    92: #efe7de,
    94: #f5ede4,
    96: #fbf2e9,
  ),
  neutral-variant: (
    0: #000000,
    10: #211b10,
    20: #372f24,
    25: #423a2e,
    30: #4e4639,
    35: #5a5144,
    40: #665d4f,
    50: #7f7667,
    60: #9a8f80,
    70: #b5aa99,
    80: #d1c5b4,
    90: #eee1cf,
    95: #fcefdd,
    98: #fff8f3,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  density: (
    scale: -1
  ),
  typography: (
    use-system-variables: false,
  ),


));

html {
  // Apply the light theme by default
  @include mat.core-theme($light-theme);
  @include mat.button-theme($light-theme);
  @include mat.all-component-themes($light-theme);
  @include mat.color-variants-backwards-compatibility($light-theme);
  @include mat.system-level-colors($light-theme);

  @include mat.system-level-typography($light-theme);

}

body {
  margin: 0;
  font-family: "Aeonik", "Helvetica Neue", sans-serif;
}



