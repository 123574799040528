.primary-button {
  border: 1px solid #f9bc49 !important;
  border-radius: 8px !important;
  background-color: #ffba0dd8 !important;
  padding: 0px 16px 0px 16px !important;
  gap: 10px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #fff !important;

  &:hover {
    background-color: #ffbb0d80 !important;
    color: #3d3833ed !important;
  }

  &:focus {
    background-color: #f5b53f !important;
    color: #3d3833ed !important;
  }

  &:disabled {
    background-color: #fff !important;
    color: #3d3833bf !important;
  }
}

.secondary-button {
  background-color: #fff !important;
  color: #3d3833bf !important;
  margin-right: 10px !important;
  border: 1px solid #3d3833bf !important;

  &:hover {
    background-color: #fff !important;
    color: #3d3833bf !important;
  }

  &:focus {
    background-color: #fff !important;
    color: #3d3833bf !important;
  }
}
